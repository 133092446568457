import api from"./api"
import ACTION_TYPES from "./actionTypes"
import termsAndConditonsStore from "../reducers/termsAndConditionsReducer"


export const fetchAllTermsAndConditions = ()  => {
    api.termsAndConditions().fetchAll()
    .then(
        response => {
            termsAndConditonsStore.dispatch({
                type:ACTION_TYPES.FETCH_ALL,
                payload:response.data
            })
        }
    )
    .catch(err => console.log(err))
}