import React, {useState} from "react"
import {Container,Row,Col} from "react-bootstrap"
import {connect} from "react-redux"
//import {} from "../actions/"
import RegistrationForm from './registrationForm'
import TermsAndConditions from "../termsAndConditions/termsAndConditions";


const TrainingRegistrations = (props) => {

return(



<div className="formcontainer">
<Container>
  <Row>
  <Col xs={8}><RegistrationForm/></Col>
    <Col className="termsandconsditions-container"><TermsAndConditions/></Col>
   

  </Row>
  
</Container>

</div>

);

};



export default TrainingRegistrations
//export default connect(mapStateToProps)(TrainingRegistrations);