import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createNewRegistration } from "../../actions/trainingRegistrationAction";


const TrainingRegistrationForm = () => {
  const { handleSubmit, register, errors, reset } = useForm();

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    title: "Mr",
    firstName: "",
    lastName: "",
    qid: "",
    email: "",
    mobile: "",
    address: "",
    city: "",
    country: "Qatar",
    zip: "",
    acceptTC: true,
    receiveFutureTrainingInfo: false,
    paymentOption: "",
    registrationId: "",
    enrolledCourses: []
    // courseNamePrice
    // preferredMonthToStart
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (data) => {
    createNewRegistration(formData)
      .then(() => {
        toast("Successfully submitted your registration.")
        handleShow();
       // reset();
      })
      .catch((error) => {console.error(error)
      toast.error("Unsuccessful. Please contact our team @ +974 44483888", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
      });
  }

  // eslint-disable-next-line no-unused-vars
  const onSubmitFailed = (data) => {
    console.warn(errors);
  }

  const onInputChange = (e) => {
    const _formData = { ...formData };
    let { name, value } = e.target;
    value = value.trim();

    if (name === "enrolledCourses") {
      // either one of the checkbox
      let scope = e.target.getAttribute("scope");
      if (e.target.checked) {
        const course = {
          courseNamePrice: value,
          preferredMonthToStart: document.getElementsByName("preferredMonthToStart")[scope].value
        }
        _formData[name].push(course);
      } else {
        _formData[name] = _formData[name].filter(course => course.courseNamePrice !== value);
      }
    } else if (name === "preferredMonthToStart") {
      // dropdown options
      let scope = e.target.getAttribute("scope");
      const parent = document.getElementsByName("enrolledCourses")[scope];
      const key = parent.value;

      if (!parent.checked) { return; }

      _formData.enrolledCourses = _formData.enrolledCourses.map(course => {
        if (course.courseNamePrice === key) {
          return {...course, preferredMonthToStart: value};
        } else {
          return course;
        }
      });
    } else if (Object.keys(_formData).includes(name)) {
      // other fields
      _formData[name] = value
    } else {
      // not found in model
      console.error("Warning: Form key " + name + " not found.");
      return;
    }

    setFormData(_formData)
  }

  const validateCourse = () => {
    var courseCheckboxes = document.getElementsByName("enrolledCourses");
    for (var i = 0, len = courseCheckboxes.length; i < len; i++) {
      if (courseCheckboxes[i].checked) {
        return true;
      }
    }

    return "Please select at least one course.";
  }


  return <>
    <form onSubmit={handleSubmit(onSubmit, onSubmitFailed)} onReset={reset}>
      <Container>
        <h4>Amadeus Course Registration Form</h4>

        <div>
          <p>
            <span><b>Choose course to enroll</b></span><br></br>
          </p>

          <Form.Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Course Name</th>
                  <th>Course Price</th>
                  <th>Your preferred month to start</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Form.Check type="checkbox" name="enrolledCourses" scope="0" value="Amadeus Basic Course - QAR 1200"
                    ref={register({ required: false, validate: validateCourse })} onChange={onInputChange} /></td>
                  <td>Amadeus Basic Course</td>
                  <td>QAR 1200</td>
                  <td>
                    <Form.Control as="select" defaultValue="Choose..." scope="0" name="preferredMonthToStart"
                      ref={register({ validate: value => value !== "" || "Please add preferred month to start" })}
                      onChange={onInputChange}>
                      <option>January</option>
                      <option>February</option>
                      <option>March</option>
                      <option>April</option>
                      <option>May</option>
                      <option>June</option>
                      <option>July</option>
                      <option>August</option>
                      <option>September</option>
                      <option>October</option>
                      <option>November</option>
                      <option>December</option>
                    </Form.Control>
                    <span className="error" > {errors.month && errors.month.message}</span>
                  </td>
                </tr>
                <tr>
                  <td><Form.Check type="checkbox" name="enrolledCourses" scope="1" value="Amadeus Advanced Course - QAR 1500"
                    ref={register({ required: false, validate: validateCourse })} onChange={onInputChange} /></td>
                  <td>Amadeus Advanced Course </td>
                  <td>QAR 1500</td>
                  <td>
                    <Form.Control as="select" defaultValue="Choose..." scope="1" name="preferredMonthToStart"
                      ref={register({ validate: value => value !== "" || "Please add preferred month to start" })}
                      onChange={onInputChange}>
                      <option>January</option>
                      <option>February</option>
                      <option>March</option>
                      <option>April</option>
                      <option>May</option>
                      <option>June</option>
                      <option>July</option>
                      <option>August</option>
                      <option>September</option>
                      <option>October</option>
                      <option>November</option>
                      <option>December</option>
                    </Form.Control>
                    <span className="error" > {errors.month && errors.month.message}</span>
                  </td>
                </tr>

              </tbody>
            </Table>
            <span className="error" > {errors.enrolledCourses && errors.enrolledCourses.message}</span>
          </Form.Row>
          <p>
            <span><b>Enter your Details</b></span>
          </p>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control as="select" defaultValue="Choose..." name="title"
                ref={register({ validate: value => value !== "" || "Please choose title" })}
                onChange={onInputChange}>
                <option>Mr</option>
                <option>Ms</option>
              </Form.Control>
              <span className="error" > {errors.title && errors.title.message}</span>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control placeholder="First Name" name="firstName"
                ref={register({ validate: value => value !== "" || "Please enter first name" })}
                onChange={onInputChange} />
              <span className="error" >{errors.firstName && errors.firstName.message}</span>

            </Form.Group>

            <Form.Group as={Col} controlId="formGridLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control placeholder="Last Name" name="lastName"
                ref={register({ validate: value => value !== "" || "Please enter last name" })}
                onChange={onInputChange} />
              <span className="error" >{errors.lastName && errors.lastName.message}</span>

            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridQid">
              <Form.Label>Qatar ID / QID</Form.Label>
              <Form.Control placeholder="QID" name="qid"
                ref={register({ validate: value => value !== "" || "Please input Qatar ID" })}
                onChange={onInputChange} />
              <span className="error" >{errors.qid && errors.qid.message}</span>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter email" name="email"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
                })}
                onChange={onInputChange} />
              <span className="error" >{errors.email && errors.email.message}</span>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control placeholder="Include Area Code eg. +974 " name="mobile"
                ref={register({ validate: value => value !== "" || "Please add mobile" })}
                onChange={onInputChange} />
              <span className="error" >{errors.mobile && errors.mobile.message}</span>
            </Form.Group>
          </Form.Row>

          <Form.Group controlId="formGridAddress1">
            <Form.Label>Address</Form.Label>
            <Form.Control placeholder="1234 Main St , Apartment, studio, or floor" name="address"
              ref={register({ validate: value => value !== "" || "Please add address" })}
              onChange={onInputChange} />
            <span className="error" >{errors.address && errors.address.message}</span>
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>City</Form.Label>
              <Form.Control name="city"
                ref={register({ validate: value => value !== "" || "Please add city" })}
                onChange={onInputChange} />
              <span className="error" >{errors.city && errors.city.message}</span>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control name="country" value="Qatar" readOnly
                ref={register({ validate: value => value !== "" || "Please add a country" })}
                onChange={onInputChange} />
              <span className="error" > {errors.country && errors.country.message}</span>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Zip</Form.Label>
              <Form.Control name="zip" ref={register} onChange={onInputChange} />
            </Form.Group>

          </Form.Row>

          <p>
            <span><b>Payment Options</b></span><br></br>
            Payment will be required at time of course confirmation. Payment options are listed below
          </p>

          <Form.Group id="formGridPaymentOptions">
            <Form.Check type="radio" label="Cash" name="paymentOption" value="Cash" checked
              ref={register} onChange={onInputChange} />
            <Form.Check type="radio" label="Bank Transfer" name="paymentOption" value="Bank Transfer"
              ref={register} onChange={onInputChange} />
          </Form.Group>

          <Form.Group id="formGridAcceptTC">
            <Form.Check type="checkbox" label="I understand and accept the terms and conditions of this registration." name="acceptTc"
              ref={register({ required: "Tick to accept" })} />
            <span className="error" >{errors.acceptTc && errors.acceptTc.message}</span>
          </Form.Group>
          <Form.Group id="formGridFutureInfo">
            <Form.Check type="checkbox" label="I want to receive future training details" name="receiveFutureTrainingInfo"
              ref={register} onChange={onInputChange} />
          </Form.Group>


          <Form.Row>
            <Button variant="secondary" type="reset" onClick={() => {
              reset({
              }, {
                errors: true,
                dirtyFields: true,
                isDirty: true,
                isSubmitted: false,
                touched: false,
                isValid: false,
                submitCount: false,
              });
            }}>
              Reset
            </Button>

            <Button variant="primary" type="submit">Submit</Button>
            <ToastContainer />
          </Form.Row>
        </div>
      </Container>
    </form>

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>

      </Modal.Header>
      <Modal.Body>Thank you! Your registration has been submitted!
        Our team will contact you as soon as possible! For inquiries please call our team at +974 44483888. </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </>;
}

export default TrainingRegistrationForm;
