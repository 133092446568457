import axios from "axios"

//const baseUrl = "http://localhost:59430/api/"
const baseUrl = "https://horrmdlo6l.execute-api.us-east-2.amazonaws.com/Prod/api/"


//export an object

export default {

registration(url = baseUrl + "trainingregistration/"){

return {
    fetchAll : () => axios.get(url),
    fetchAllById : id => axios.get(url + id),
    create: newRecord => axios.post(url,newRecord),
    upate:(id, updateRecord) => axios.put(url+id,updateRecord),
    delete : id => axios.delete(url + id)
}
},


termsAndConditions(url = baseUrl + "termsandconditions/"){

    return {
        fetchAll : () => axios.get(url),
        fetchAllById : id => axios.get(url + id),
        create: newRecord => axios.post(url,newRecord),
        upate:(id, updateRecord) => axios.put(url+id,updateRecord),
        delete : id => axios.delete(url + id)
    }
    }


}