import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect, Route, Switch
} from "react-router-dom";
import { store } from "./actions/store";
import "./App.css";
import "./assets/css/style.css";
import TrainingRegistration from "./components/registration";


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/training">
            <Switch>
              <Route path="/training/registration">
                <TrainingRegistration />
              </Route>
            </Switch>
          </Route>
          <Route path="/">
            <Redirect to="/training/registration" />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
