import api from "./api"
import ACTION_TYPES from "./actionTypes"
import trainingRegistrationStore from "../reducers/trainingRegistrationReducer"

// showing what operations we do in action

// empty parameter
// function returns a dispathc function
// dispatch actions and trigger state changes to the store. react-redux is simply trying to give you convenient access to it.
export const fetchAll = () => {
  api.registration().fetchAll()
    .then(
      response => {
        trainingRegistrationStore.dispatch({
          type: ACTION_TYPES.FETCH_ALL,
          payload: response.data
        })
      }
    )
    .catch(err => console.log(err))
}


export const createNewRegistration = (data) => {
  const promise = api.registration().create(data);

  promise.then(res => {
    trainingRegistrationStore.dispatch({
      type: ACTION_TYPES.CREATE,
      payload: res.data
    })
  })

  return promise;
}
