import React, { useState, useEffect } from "react"
import termsAndConditonsStore from "../../reducers/termsAndConditionsReducer"
import { fetchAllTermsAndConditions } from '../../actions/termsAndConditionsAction'

const TermsAndConditions = (props) => {


const [termsAndConditionsList, setTermsAndConditions] = useState([]);

useEffect (() => {

    fetchAllTermsAndConditions();
    termsAndConditonsStore.subscribe(() => {
        
        setTermsAndConditions(termsAndConditonsStore.getState().list)
        
    })

},[]);

return(

    <div>
{termsAndConditionsList.map((value) => 
    <div key={value.type}>
        <span>
        <b>{value.type}</b>
        </span>

        <p>
        {value.description}
        </p>
    </div>
)}
    </div>
);

}

export default TermsAndConditions